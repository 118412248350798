import * as compareVersions from "compare-versions";
import { currentLanguage } from "./translation";

/**
 * Copy the `text` into the clipboard.
 * @param text The text to be copied in the clipboard.
 * @return `true` if the text has been successfully copied, false otherwise.
 */
export async function copyIntoClipboard(text) {
  await navigator.clipboard.writeText(text)
};

export function timeDate(timestamp) {
  let delta = timestamp / 1000;
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = Math.floor(delta % 60);  // in theory the modulus is not required

  return { days, hours, minutes, seconds };
}

export function getAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function WaitTime(time){
  return new Promise((res) => { setTimeout(res,time) })
}

/**
 * Transform a string to be easly compared to another
 * @param {string} element a value
 * @returns the value filtered
 */
export function searchFilter(element: string) {
  let newElement = element.toLocaleLowerCase() + "";
  newElement = newElement.replaceAll(/é|è|ê|ë/g, "e");
  newElement = newElement.replaceAll(/à|ä|â/g, "a");
  newElement = newElement.replaceAll(/î|ï/g, "i");
  newElement = newElement.replaceAll(/ç/g, "c");
  newElement = newElement.replaceAll(/ö|ô/g, "o");
  newElement = newElement.replaceAll(/ù|ü|û/g, "u");
  newElement = newElement.replaceAll(/#|\/|\\|\^/g, " ");
  return newElement;
}

export function shuffleArray(arr){
  return [...arr].map( (_, i, arrCopy) => {
      var rand = i + ( Math.floor( Math.random() * (arrCopy.length - i) ) );
      [arrCopy[rand], arrCopy[i]] = [arrCopy[i], arrCopy[rand]]
      return arrCopy[i]
  })
}

/**
 * Toggle element from array
 * @param {Array<any>} array an array of any value
 * @param {any} item an item
 */
export function ToggleElement(array, item){
  const indexOf = array.indexOf(item);
  if(indexOf < 0) array.push(item);
  else array.splice(indexOf, 1);
}

export function autoFilter(element){
  if(!element) return element;
  return (element + "").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

/**
 * @param {*} version Version to test
 * @param {*} moduleElement Description of the module
 * @returns False if no version found, otherwise the nerest cache
 * 
 * Example of cachedData compatibility (compatibility ─────► @cacheVersion )
 *
 *                to test : 0.0.9
 *                      -
 * 0.0.5 ───────────────┼──► @0.0.10
 *             0.0.8 ───■─────────► @0.0.15
 *                      │    0.1.0 ───────────► @0.1.5
 *                      ▼
 *   cache @0.0.15 taken, because that the nearest updated
 */
export function ValidCacheVersion(version, moduleElement){
  if(!version || version == '') return [];
  // console.log(version, moduleElement.cachedData, moduleElement.defaultCache);
  const versions = Object.keys(moduleElement.cachedData);
  versions.sort((a, b) => compareVersions.compare(a, b, ">") ? -1 : 1 );
  const valid : string[] = [];
  for(let i = 0; i < versions.length; i++){
    const versionElement = versions[i];
    const element = moduleElement.cachedData[versionElement];
    if(compareVersions.compare(versionElement, version, '<')) break; //Not correct compatibility can be found
    
    if(element.compatibility && compareVersions.validate(element.compatibility)){
      if(compareVersions.compare(version, element.compatibility, '>=')){
        valid.push(versionElement);
      }
    }
  }
  
  return valid;
}

export function BestCacheVersion(version, moduleElement){
  const valids = ValidCacheVersion(version, moduleElement);
  if(valids.length > 0) return valids[0];

  const versions = Object.keys(moduleElement.cachedData);
  return moduleElement.defaultCache ? false : versions[0] ?? false;
}

export function GenerateGUID() {
  if (crypto?.randomUUID) return crypto?.randomUUID();
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function Same(objA: any, objB: any){
  return JSON.stringify(objA) != JSON.stringify(objB);
}

export function formatedDate(date, options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }) {
  const realDate = new Date(date);
  if(!date || !(realDate instanceof Date) || isNaN(realDate.getTime())) return;
  return realDate.toLocaleDateString(currentLanguage.value, options);
}

export function relativeDate(date, locale = undefined) {
  const realDate = new Date(date);
  if (!date || !(realDate instanceof Date) || isNaN(realDate.getTime())) return;
  const now = new Date();
  const diff = realDate.getTime() - now.getTime();
  const timeUnits = [
    { unit: 'year', threshold: 365 * 24 * 60 * 60 * 1000 },
    { unit: 'month', threshold: 30 * 24 * 60 * 60 * 1000 },
    { unit: 'day', threshold: 24 * 60 * 60 * 1000 },
    { unit: 'hour', threshold: 60 * 60 * 1000 },
    { unit: 'minute', threshold: 60 * 1000 },
    { unit: 'second', threshold: 1000 }
  ];
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  for (let i = 0; i < timeUnits.length; i++) {
    const { unit, threshold } = timeUnits[i];

    if (Math.abs(diff) >= threshold) {
      const value = Math.round(diff / threshold);
      return rtf.format(value, unit as any);
    }
  }

  // If the date is within the last second, return "just now"
  return rtf.format(0, 'second');
}

export function loadUtilitiesSystem(app){
  app.mixin({
    methods: {
      formatedDate,
      relativeDate,
    }
  });
}

export function HashCode(s) {
  let h = 0;
  for(let i = 0; i < s.length; i++) 
    h = Math.imul(31, h) + s.charCodeAt(i) | 0;
  return h;
}

export function ToggleDark(){
  document.body.classList.toggle("dark");
}

export function SetDarkMode(value = true){
  if(value) document.body.classList.add("dark");
  else document.body.classList.remove("dark");
}

export async function CopyText(text: string){
  return navigator.clipboard.writeText(text);
}

export function HexToHSL(hex: string){
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if(!result) return { h: 0, s: 0, l: 0};

    let r = parseInt(result[1], 16) / 255;
    let g = parseInt(result[2], 16) / 255;
    let b = parseInt(result[3], 16) / 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return {
      h: Math.round(360*h),
      s: Math.round(s*100),
      l: Math.round(l*100),
    }
}

export function Clone(obj){
  return JSON.parse(JSON.stringify(obj));
}


/**
 * Distance between 2 strings
 * @param {string} str1 First string
 * @param {string} str2 Last string
 * @returns levenshtein distance
 */
export function LevenshteinDistance(str1, str2) : number {
  // Si une des chaînes est vide, renvoyer la longueur de l'autre chaîne
  if (str1.length === 0) return str2.length;
  if (str2.length === 0) return str1.length;

  // Initialiser la matrice de distance avec des zéros
  const distanceMatrix: number[][] = [];
  for (let i = 0; i <= str1.length; i++) {
    distanceMatrix.push(new Array(str2.length + 1).fill(0));
  }

  // Remplir la première colonne et la première ligne de la matrice avec des entiers croissants
  for (let i = 0; i <= str1.length; i++) {
    distanceMatrix[i][0] = i;
  }
  for (let j = 0; j <= str2.length; j++) {
    distanceMatrix[0][j] = j;
  }

  // Remplir le reste de la matrice en utilisant la formule de distance de Levenshtein
  for (let i = 1; i <= str1.length; i++) {
    let previousColumnValue = distanceMatrix[i - 1][0];
    for (let j = 1; j <= str2.length; j++) {
      const cost = (str1[i - 1] === str2[j - 1]) ? 0 : 1;
      distanceMatrix[i][j] = Math.min(
        distanceMatrix[i - 1][j] + 1,
        distanceMatrix[i][j - 1] + 1,
        previousColumnValue + cost
      );
      previousColumnValue = distanceMatrix[i - 1][j];
    }
  }

  // Renvoyer la dernière valeur de la matrice (distance entre les deux chaînes)
  return distanceMatrix[str1.length][str2.length];
}

async function Hash256(message) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return hash;
}
/**
 * 
 * @param { { firstName: string, lastName: string } } nameA 
 * @param { { firstName: string, lastName: string } } nameB 
 */
export function CompareLevenshtein(nameA, nameB){
  return Math.min(
    LevenshteinDistance(nameA.firstName, nameB.firstName) + LevenshteinDistance(nameA.lastName, nameB.lastName),
    LevenshteinDistance(nameA.firstName, nameB.lastName) + LevenshteinDistance(nameA.lastName, nameB.firstName),
  );
}

export function urlBase64ToUint8Array(base64String) {
  let padding = '='.repeat((4 - base64String.length % 4) % 4);
  let base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  let rawData = window.atob(base64);
  let outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}